import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "jasonDinsmore",
  "categories": ["rails", "ruby"],
  "date": "2021-07-06",
  "path": "/blog/Active-Record-Encryption",
  "summary": "We take a look at how to use Active Record Encryption and discuss some of its assets and limitations.",
  "title": "Active Record Encryption",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Active Record Encryption",
      "description": "We take a look at how to use Active Record Encryption and discuss some of its assets and limitations."
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia",
      "creator": "@dinjas"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Rails 7 will be introducing a very cool new feature for ActiveRecord - application-level encryption, summoned by the mighty `}<inlineCode parentName="p">{`encrypts`}</inlineCode>{` declaration in a model. This new feature provides a layer of encryption that sits between our application code and the database. In essence, when our data using Active Record Encryption has been loaded into an AR object, it will be unencrypted, and when it is sitting in the database it will be encrypted.`}</p>
    <p>{`In this post, we will take a high-level look at how to use this functionality, discuss some cool things it can do, and acknowledge some of its limitations.`}</p>
    <p>{`Before we dive in, I would be remiss if I didn't point to the excellent documentation in the `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/active_record_encryption.html"
      }}>{`Rails guide`}</a>{`.`}</p>
    <p>{`For the duration of the post, I will refer to the feature as `}<strong parentName="p">{`encrypts`}</strong>{` for brevity (and also because I am not sure what else to call it 😉).`}</p>
    <h2>{`Backstory`}</h2>
    <p><strong parentName="p">{`encrypts`}</strong>{` was merged into Rails in `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/pull/41659"
      }}>{`PR 41659`}</a>{` by `}<a parentName="p" {...{
        "href": "https://github.com/jorgemanrubia"
      }}>{`@jorgemanrubia`}</a>{`. In the PR description he states that the functionality is an extraction from `}<a parentName="p" {...{
        "href": "https://hey.com/"
      }}>{`HEY`}</a>{`, which had the implementation reviewed by a security firm. If you are interested in hearing more of the story behind the feature, I'd recommend `}<a parentName="p" {...{
        "href": "https://world.hey.com/jorge/a-story-of-rails-encryption-ce104b67"
      }}>{`Jorge's blog post`}</a>{` on the subject - it's an interesting read.`}</p>
    <h2>{`Deterministic Sidebar`}</h2>
    <p>{`Let's take a quick second to discuss the difference between deterministic and non-deterministic encryption. It's really pretty simple, but is a central tenet in understanding how to use `}<strong parentName="p">{`encrypts`}</strong>{`.`}</p>
    <p>{`Think of encryption as a function that is applied to some input (text), resulting in some output (encrypted text).`}</p>
    <p>{`If the function is deterministic, then any time the function is applied to the same text, it will output the same result.`}</p>
    <p>{`If the encryption function is non-deterministic, then we can't predict what the output will be the second time we encrypt a given value. In theory, it is possible we would get the same result we got the first time, but the odds of that happening are very, very low. In the context of `}<strong parentName="p">{`encrypts`}</strong>{` with its default non-deterministic configurations, subsequent encryptions of the same plaintext are almost certain to produce different ciphertexts.`}</p>
    <p>{`If we are using deterministic encryption with `}<strong parentName="p">{`encrypts`}</strong>{` for a model attribute, then any two rows in the database that would have the same plaintext value will also have the same stored encrypted value. If we using non-deterministic encryption, then two rows that have the same plaintext value will generally have different encrypted values. As we'll see in a bit, this has implications as to whether we can query the encrypted data.`}</p>
    <h2>{`Setup`}</h2>
    <p>{`There's not a ton of configuration required to get going with `}<strong parentName="p">{`encrypts`}</strong>{`, but there are some things to be aware of.`}</p>
    <h3>{`Keys`}</h3>
    <p>{`The main requisite is that we will need to generate a set of keys and add them to our credentials file(s). We can generate the keys to add by running: `}<inlineCode parentName="p">{`bin/rails db:encryption:init`}</inlineCode>{`, which will output something like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Add`}</span>{` this entry to the credentials of the target environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

active_record_encryption`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  primary_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` zxMXS0hBbpa5BzRKPv9HOSF9etBySiHQ
  deterministic_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`pM2UHHBQr1kf1irO6JgakcSOXu0r1Vn
  key_derivation_salt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`I5AkViD0UJhSqK3NY49Zvsls3ZoifyXx`}</span></code></pre></div>
    <p>{`The `}<inlineCode parentName="p">{`primary key`}</inlineCode>{` is used to derive the root encryption key for non-deterministic encryption. Note that the `}<inlineCode parentName="p">{`primary_key`}</inlineCode>{` value in the credentials file can also be a list of keys.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`deterministic_key`}</inlineCode>{` is used for deterministic encryption. If you recall from the section on determinism above, we'll get the same result if we encrypt the same data with this key multiple times. Currently, `}<strong parentName="p">{`encrypts`}</strong>{` does not support using a list of keys for deterministic encryption. If we want to completely disable deterministic encryption, not providing the key is a sure-fire off switch.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`key_derivation_salt`}</inlineCode>{` is used to derive encryption keys.`}</p>
    <h3>{`App Configuration`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/v7.0.0/active_record_encryption.html#configuration-options"
      }}><strong parentName="a">{`encrypts`}</strong>{` API`}</a>{` provides several configuration options. All of the options are defined in the `}<inlineCode parentName="p">{`config.active_record.encryption`}</inlineCode>{` namespace and I'd encourage reading through them if you are going to use this feature. I believe you'll find that most of the options have pretty reasonable defaults.`}</p>
    <p>{`I will mention the `}<inlineCode parentName="p">{`config.active_record.encryption.extend_queries`}</inlineCode>{` option as it defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`, but enabling it has several implications:`}</p>
    <ul>
      <li parentName="ul">{`enables querying unencrypted data in an encrypted column (also need to enable `}<inlineCode parentName="li">{`config.active_record.encryption.support_unencrypted_data`}</inlineCode>{` for this)`}</li>
      <li parentName="ul">{`allows supporting multiple encryption schemes`}</li>
      <li parentName="ul">{`enables  support for uniqueness validations`}</li>
    </ul>
    <h3>{`Database`}</h3>
    <p>{`When an encrypted string or text attribute is stored in the database, it isn't stored as an ordinary string or text - it is stored as a more complex data structure that is serialized when written and deserialized when read. This data structure allows some meta information to be stored along with the encrypted text, which gives the app some clues about how the text was encrypted.`}</p>
    <p>{`This extra meta-info introduces some storage overhead - up to 250 bytes.`}</p>
    <p>{`The guide recommends increasing the size of a 255 byte string field to 512 bytes if using encryption on a column. It also says that for a text field, the overhead is generally negligible.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "62.576687116564415%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEFA//EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAG7OanSiZCH/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxITMv/aAAgBAQABBQJvILLZoRbWLWAluep//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAIhYf/aAAgBAwEBPwGGqf/EABcRAQADAAAAAAAAAAAAAAAAAAACIWH/2gAIAQIBAT8Bni3/xAAaEAACAgMAAAAAAAAAAAAAAAAAASIxEyGR/9oACAEBAAY/AmSemWY30skz/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIUEx/9oACAEBAAE/IaaNFS1dvFiPomJmEa8X5E0UWuZP/9oADAMBAAIAAwAAABCTP//EABgRAAMBAQAAAAAAAAAAAAAAAAABETFR/9oACAEDAQE/EIrWCdH/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExUf/aAAgBAgEBPxBNDqXxP//EAB0QAQEAAwACAwAAAAAAAAAAAAERACExQVFxgfD/2gAIAQEAAT8QQSQlak+zJMPYSF0l/TIEdKVlMpzYjO0vrDZ7APA5O4HEIOAfGf/Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Dog burying a bone",
        "title": "Dog burying a bone",
        "src": "/static/ad793e69852299349fc3869fb8f0a079/6aca1/dogbone.jpg",
        "srcSet": ["/static/ad793e69852299349fc3869fb8f0a079/d2f63/dogbone.jpg 163w", "/static/ad793e69852299349fc3869fb8f0a079/c989d/dogbone.jpg 325w", "/static/ad793e69852299349fc3869fb8f0a079/6aca1/dogbone.jpg 650w", "/static/ad793e69852299349fc3869fb8f0a079/2551b/dogbone.jpg 748w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2>{`Invocation`}</h2>
    <p>{`Finally, we can talk about actually using the thing!`}</p>
    <p>{`In the most basic use case, to encrypt a single column, we simply add an `}<strong parentName="p">{`encrypts`}</strong>{` declaration to our model for the attribute we want to encrypt. For example, if we had a `}<inlineCode parentName="p">{`Dog`}</inlineCode>{` model with a `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` field (dogs like to hide their toys, you know) that needs encryption, our model would look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Dog`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationRecord`}</span>{`
    encrypts `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:toy_location`}</span></code></pre></div>
    <p>{`Pretty simple, eh?`}</p>
    <h2>{`Writing`}</h2>
    <p>{`Writing an encrypted attribute is completely transparent. We just do what we would normally do in Rails:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` dog `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Bruno'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toy_location`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'top secret'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`If we were to look at the content sitting in the database directly, we would see something like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`connection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`execute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'SELECT toy_location FROM dogs LIMIT 1'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`first
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1.4`}</span>{`ms`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`SELECT`}</span>{` toy_location `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FROM`}</span>{` dogs `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`LIMIT`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"oVgEJvRaX6DJvA==\\",\\"h\\":{\\"iv\\":\\"WYypcKysgBY05Tum\\",\\"at\\":\\"OaBswq+wyriuRQO8yCVD3w==\\"}}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The value here is just serialized JSON, let's go ahead and parse it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'toy_location'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oVgEJvRaX6DJvA=="`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"h"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"iv"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"WYypcKysgBY05Tum"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"at"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"OaBswq+wyriuRQO8yCVD3w=="`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`That gave us a Hash. Most of the keys in this Hash are defined in the `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/c9a0f1ab9616ca8e94f03327259ab61d22f04b51/activerecord/lib/active_record/encryption/properties.rb#L23-L30"
      }}><inlineCode parentName="a">{`ActiveRecord::Encryption::Properties::DEFAULT_PROPERTIES`}</inlineCode></a>{` constant. `}<inlineCode parentName="p">{`p`}</inlineCode>{` is the payload, aka the encrypted plaintext. `}<inlineCode parentName="p">{`h`}</inlineCode>{` is a Hash of headers that contain information relating to the encryption operation. Here, `}<inlineCode parentName="p">{`iv`}</inlineCode>{` is the initialization vector the plaintext was encrypted with - more about this in the next section on searching, and `}<inlineCode parentName="p">{`at`}</inlineCode>{` is an `}<inlineCode parentName="p">{`auth_tag`}</inlineCode>{` that will be used during the decryption process to verify that the encrypted text hasn't been altered. You may notice other headers from the `}<inlineCode parentName="p">{`DEFAULT_PROPERTIES`}</inlineCode>{` Hash above depending on how your encryption is set up and being used.`}</p>
    <h2>{`Reading`}</h2>
    <p>{`When we load a model with an encrypted attribute, Rails will seamlessly decrypt the encrypted value. Let's find the `}<inlineCode parentName="p">{`Dog`}</inlineCode>{` we created above by his name:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find_by`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Bruno'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`toy_location
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span>{` id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Bruno"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toy_location`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"top secret"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` created_at`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2021-05-28 22:41:23.142635000 +0000"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` updated_at`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2021-05-28 22:41:23.142635000 +0000"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`As you can see, the encrypted value was automatically translated to a readable attribute on our model instance - pretty slick.`}</p>
    <h2>{`Searching`}</h2>
    <p>{`What if we wanted to find Bruno by his `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` instead of his name? We can do that just like we would if the field were not encrypted:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` dog `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find_by`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`toy_location`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'top secret'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Load`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2.1`}</span>{`ms`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`SELECT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dogs"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FROM`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dogs"`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`WHERE`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dogs"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`LIMIT`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"oVgEJvRaX6DJvA==\\",\\"h\\":{\\"iv\\":\\"WYypcKysgBY05Tum\\",\\"at\\":\\"OaBswq+wyriuRQO8yCVD3w==\\"}}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"LIMIT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#<Dog id: 1, name: "Bruno", toy_location: "top secret", created_at: "2021-05-28 22:41:23.142635000 +0000", updated_at: "2021-05-28 22:41:23.142635000 +0000">`}</span></code></pre></div>
    <p>{`Notice that our query string was automatically converted into the encrypted JSON string we saw when we looked in the database.`}</p>
    <h3>{`Initialization Vector/Determinism`}</h3>
    <p>{`When using deterministic encryption, all records with the same plaintext value will use the same initialization vector to encrypt. This is so ActiveRecord will generate the same ciphertext for the same input, which is a prerequisite for being able to search the encrypted data. Under the hood, Rails uses the plaintext to generate the initialization vector for deterministically encrypted data - otherwise the IV is randomly generated.`}</p>
    <p>{`If two rows with the same plaintext were to use different initialization vectors to perform the encryption, the serialized JSON that ends up in the database would be completely different.`}</p>
    <p>{`In order to be able to perform searches on the encrypted data, the stored values need to be exactly the same.`}</p>
    <p>{`This means that all of the stored values in the serialized hash need to be identical for two rows that have the same text value, AND that Rails can re-compute the exact same hash on the fly to find rows that are matches for the search string.`}</p>
    <p>{`Determinism at its finest.`}</p>
    <h3>{`Searching Plaintext`}</h3>
    <p>{`What if we did not have the luxury of starting with pristine data? For example, if our `}<inlineCode parentName="p">{`Dog`}</inlineCode>{` table already existed and had a pre-existing `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` column on it that was not encrypted?`}</p>
    <p>{`Well, as we can see by the query generated above, if we had a `}<inlineCode parentName="p">{`Dog`}</inlineCode>{` record with `}<inlineCode parentName="p">{`top secret`}</inlineCode>{` (unencrypted) as its `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{`, that query aint gonna find it. Also, it seems pretty likely that if we try to load a `}<inlineCode parentName="p">{`Dog`}</inlineCode>{` record with stored plaintext into memory, Rails is going to have problems when it attempts to decrypt the plaintext.`}</p>
    <p>{`One option we have would be to convert our plaintext data to encrypted data, which seems ideal to me. We may have reasons to want to avoid doing a data migration like that, however.`}</p>
    <p>{`In that case, `}<strong parentName="p">{`encrypts`}</strong>{` will allow us to keep storing the plaintext values as plaintext, and will encrypt any new or updated data. To opt-in to supporting a mix of encrypted/unencrypted data, enable the `}<inlineCode parentName="p">{`config.active_record.encryption.support_unencrypted_data`}</inlineCode>{` configuration option.`}</p>
    <p>{`Enabling this behavior will prevent the errors we would get when it tries to decrypt the plaintext and will also allow us to perform searches when a column contains a mismatch of plaintext and encrypted data.`}</p>
    <p>{`If we enable the setting and re-run our search query above, we'll see:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Load`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`ms`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`SELECT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dogs"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FROM`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dogs"`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`WHERE`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dogs"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`IN`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`LIMIT`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"Bd+/TzEysF2CCQ==\\",\\"h\\":{\\"iv\\":\\"R2IUJJ+EmnDnZvQP\\",\\"at\\":\\"zqG5WAJql1zgctRCPpoBkQ==\\"}}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"top secret"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"LIMIT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Now, it is looking for records that have the encrypted content or have the plaintext version of that content. Perfect!`}</p>
    <h3>{`Case Insensitive Searches`}</h3>
    <p>{`By default, searching is case sensitive. If we need to ignore case when searching for some reason, we have a few options:`}</p>
    <p><strong parentName="p">{`Option 1:`}</strong></p>
    <p>{`We can query for all of the case variations we need to match - eg. `}<inlineCode parentName="p">{`Dog.where(toy_location: ['Top secret', 'top secret'])`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Option 2:`}</strong></p>
    <p>{`We can specify `}<inlineCode parentName="p">{`downcase: true`}</inlineCode>{` on our `}<strong parentName="p">{`encrypts`}</strong>{` declaration. This will cause the text to be downcased before it is stored. ActiveRecord will automatically downcase our search text when performing queries. The downside here is that all case information is lost when it is downcased. Sorry to be a downer.`}</p>
    <p><strong parentName="p">{`Option 3:`}</strong></p>
    <p>{`We can specify `}<inlineCode parentName="p">{`ignore_case: true`}</inlineCode>{` on the `}<strong parentName="p">{`encrypts`}</strong>{` declaration and add an `}<inlineCode parentName="p">{`original_column_name`}</inlineCode>{` column to our database (eg. `}<inlineCode parentName="p">{`original_toy_location`}</inlineCode>{`). With this in place, if we created a dog with an uppercase letter in the encrypted field:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Dog`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Max'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toy_location`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Top secret'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`the `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` column would be populated with the encrypted form of `}<inlineCode parentName="p">{`'top secret'`}</inlineCode>{` (the value downcased), and the `}<inlineCode parentName="p">{`original_toy_location`}</inlineCode>{` column will have the encrypted form of `}<inlineCode parentName="p">{`'Top secret'`}</inlineCode>{` (the value we set).`}</p>
    <p>{`Any searches would be done against the `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` column, and the model's `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` attribute would be populated from the `}<inlineCode parentName="p">{`original_toy_location`}</inlineCode>{` column when it is loaded into memory.`}</p>
    <p>{`One thing to note here - while the `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` column is encrypted deterministically in this situation (so it can be searched), the `}<inlineCode parentName="p">{`original_toy_location`}</inlineCode>{` column appears to be encrypted non-deterministically. This makes sense, since that column does not need to support searching. This can be confirmed by comparing the `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` and `}<inlineCode parentName="p">{`original_toy_location`}</inlineCode>{` values for two records with the same plaintext value. As you can see below, they have the same stored values (initialization vector, payload, etc) for the `}<inlineCode parentName="p">{`toy_location`}</inlineCode>{` column (searchable and downcased), and different stored values for the `}<inlineCode parentName="p">{`original_toy_location`}</inlineCode>{` column (not searchable, case preserved):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"Bd+/TzEysF2CCQ==\\",\\"h\\":{\\"iv\\":\\"R2IUJJ+EmnDnZvQP\\",\\"at\\":\\"zqG5WAJql1zgctRCPpoBkQ==\\"}}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"original_toy_location"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"5syLqDK6GCbBDw==\\",\\"h\\":{\\"iv\\":\\"KBGp4FrI7oL4/a3p\\",\\"at\\":\\"JnH6hxLX35cAwroImk2XqQ==\\"}}"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"toy_location"`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"Bd+/TzEysF2CCQ==\\",\\"h\\":{\\"iv\\":\\"R2IUJJ+EmnDnZvQP\\",\\"at\\":\\"zqG5WAJql1zgctRCPpoBkQ==\\"}}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"original_toy_location"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"p\\":\\"0246w4+SSqqlJw==\\",\\"h\\":{\\"iv\\":\\"1uEnjlCNot9sYNgR\\",\\"at\\":\\"UhkhK6YlOTxJg75juqIMGA==\\"}}"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Other Cool things`}</h2>
    <p><strong parentName="p">{`encrypts`}</strong>{` does even more than we have looked at so far. In the interest of not writing a book, I'm not going to go into much detail here, but would like to mention some of its other capabilities.`}</p>
    <p>{`We have only looked at encrypting simple strings, but `}<strong parentName="p">{`encrypts`}</strong>{` can encrypt rich text attributes too.`}</p>
    <p>{`It also provides support for `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/v7.0.0/active_record_encryption.html#support-for-previous-encryption-schemes"
      }}>{`previous encryption schemes`}</a>{`. This means that we can start out using non-deterministic encryption on a column and change to using deterministic encryption later on. I would definitly recommend reading the fine print before using this feature.`}</p>
    <p>{`We can `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/v7.0.0/active_record_encryption.html#rotating-keys"
      }}>{`rotate our (non-deterministic) keys`}</a>{`. This is pretty cool, just note that it is not currently supported for deterministic encryption.`}</p>
    <p>{`Related to the rotating keys, we can configure `}<strong parentName="p">{`encrypts`}</strong>{` to `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/v7.0.0/active_record_encryption.html#storing-key-references"
      }}>{`store a reference`}</a>{` to the key used to encrypt in the encrypted data itself.`}</p>
    <p>{`If using deterministic encryption, `}<strong parentName="p">{`encrypts`}</strong>{` supports using `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/v7.0.0/active_record_encryption.html#unique-constraints"
      }}><inlineCode parentName="a">{`unique`}</inlineCode>{` constraints`}</a>{`. If we need to ensure uniqueness in any of our encrypted columns, there are some things to be aware of. Be sure to read up on it first.`}</p>
    <p>{`Encrypted columns are automatically filtered from Rails logs by default. `}<strong parentName="p">{`encrypts`}</strong>{` provides a way to disable this functionality.`}</p>
    <p>{`This might be a good place to mention that the implementation is modular, and allows quite a bit of customization. Many of `}<strong parentName="p">{`encrypts`}</strong>{` options can be set on a per-attribute basis, or at more global levels.`}</p>
    <h2>{`Limitations`}</h2>
    <p>{`Even with all of its glory, `}<strong parentName="p">{`encrypts`}</strong>{` does have limitations. We'll take a look at a few that stood out to me. Given the variety of applicable use cases and the breadth of the functionality, I am sure other folks will have their own list.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fuzzy searching`}</strong>{` - The search functionality `}<strong parentName="li">{`encrypts`}</strong>{` provides requires an exact match on search text. This means doing a `}<inlineCode parentName="li">{`LIKE`}</inlineCode>{` query, for example, won't work. It also means that any queries done on encrypted columns will need to go through Rails and ActiveRecord vs being manually crafted in SQL.`}</li>
      <li parentName="ul"><strong parentName="li">{`Rich text search`}</strong>{` - While it is rad that `}<strong parentName="li">{`encrypts`}</strong>{` can encrypt rich text, it can only do so non-deterministically right now. This means that we won't be able to search rich text.`}</li>
      <li parentName="ul"><strong parentName="li">{`Deterministic searching does not support multiple keys`}</strong>{` - Something good to be aware of going in - if using deterministic encryption/searching, we won't have the ability to use more than one key at a time. If we need to change keys, we'll likely need to do something fancy.`}</li>
      <li parentName="ul"><strong parentName="li">{`Rails console exposes data`}</strong>{` - This may seem obvious, but if a malicious person gets access to our Rails console, they can load encrypted data into objects and view the plaintext all day long. In `}<a parentName="li" {...{
          "href": "https://world.hey.com/jorge/a-story-of-rails-encryption-ce104b67"
        }}>{`Jorge's post`}</a>{`, he mentioned that HEY is using a console extension that sits on top of the encryption feature that protects and audits console access. Unfortunately, that is a private gem (named console1984) and not available in Rails (at this time).`}</li>
      <li parentName="ul"><strong parentName="li">{`Deterministic encryption reduces security`}</strong>{` - I don't think this is a fault of the implementation per se, but if we use deterministic encryption, then any two rows that have the same value for an encrypted attribute will have the same value stored in the database. While we can't necessarily reverse engineer how it was encrypted, if we know what one of the row's plaintext value is, then we know what the other row's plaintext value is as well. Non-deterministic encryption doesn't have this same weakness.`}</li>
    </ul>
    <h2>{`Summary`}</h2>
    <p>{`TLDR: I am pretty excited and intrigued by this feature. I think it will be cool to see how people use it and how it evolves over time. My hunch is that some of the current limitations will go away (like not supporting multiple keys for deterministically encrypted attributes) as more people begin using it and digging through the code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      